<template>
	<div class="myproduct">
		<div class="my-title">
			<p>我机构产品</p>
		</div>
		<div style="margin: 0 20px">
			<div style="padding: 0 10px 10px 0">
				<div>
					<span>产品名称:</span>
					<el-input style="width: 15%; margin: 0 10px" placeholder="请输入产品名称"
						v-model="params.financialProductName"></el-input>
					<span>产品标签:</span>
					<el-input style="width: 15%; margin: 0 10px" placeholder="请输入产品标签" v-model="params.productType"></el-input>
					<span>产品类别:</span>
					<el-select clearable style="width: 15%; margin: 0 10px" v-model="params.productCategory" placeholder="请选择">
						<el-option v-for="item in ProductCategory" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-button type="primary" @click="() => {
							params.pageNo = 1;
							getFinancialProductList();
						}
							">查询</el-button>

					<el-button type="primary" @click="() => {
							params.financialProductName = '';
							params.productType = '';
							params.productCategory = '';
							params.pageNo = 1;
							getFinancialProductList();
						}
							">清空</el-button>
					<el-button v-if="$store.state.userInfo &&
							$store.state.userInfo.financialOrganizationsId
							" type="primary" @click="add">新增</el-button>
				</div>
			</div>
			<!-- table 列表 -->
			<el-table height="calc(100vh - 290px)" :data="tableData" border style="width: 100%">
				<el-table-column align="left" prop="financialProductName" label="产品名称">
					<template slot-scope="scope">
						<p style="color: #02a7f0; cursor: pointer" @click="examinehandle(scope.$index, scope.row)">
							{{ scope.row.financialProductName }}
						</p>
					</template>
				</el-table-column>
				<el-table-column align="left" prop="productType" label="产品标签">
				</el-table-column>
				<el-table-column align="left" prop="productCategoryName" label="产品类别">
				</el-table-column>
				<el-table-column align="left" prop="examineStatus" label="审核状态">
				</el-table-column>
				<el-table-column width="180" fixed="right" align="center" label="操作">
					<template slot-scope="scope">
						<p @click="handleEdit(scope.$index, scope.row)" style="
								color: rgb(2, 167, 240);
								cursor: pointer;
								margin-right: 17px;
							">
							编辑
						</p>
					</template>
				</el-table-column>
				<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
			</el-table>
			<!-- 分页 -->
			<div class="fy">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="params.pageNo" :page-sizes="[5, 10, 20, 30, 50]" :page-size="10"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>

		<infoDrawer @saveData="saveData" @close="dialogVisible = false" :allDictionaries="allDictionaries"
			:dialogVisible.sync="dialogVisible" :mapOptions="mapOptions" :dataType="dataType" :dataObj="dataObj"
			:ProductCategory="ProductCategory" :ProductRepaymentMethod="ProductRepaymentMethod"
			:CheckCreditScan="CheckCreditScan" />
		<examineDrawer @close="examineVisible = false" :dataObj="dataObj" :examineVisible.sync="examineVisible" />
	</div>
</template>

<script>
import {
	itemByType,
	financialProductList,
	networkAreaList,
} from '@/api/index.js';
import infoDrawer from '../mobileproduct/module/infoDrawer.vue';
import examineDrawer from '../mobileproduct/module/examineDrawer.vue';
export default {
	name: 'myproduct',
	data() {
		return {
			tableData: [],
			dataType: '',
			dialogVisible: false,
			examineVisible: false,
			currentPage: 1,
			params: {
				financialOrganizationsId:
					this.$store.state.userInfo.financialOrganizationsId || -1,
				financialProductName: '',
				productType: '',
				productCategory: '',
				pageSize: 10,
				pageNo: 1,
			},
			total: 0,
			ProductCategory: [],
			ProductRepaymentMethod: [],
			CheckCreditScan: [],
			allDictionaries: {
				CompanyOrPersonal: [],
				OnlineOrOffline: [],
				MortgageOrCredit: [],
				MortgageRate: [],
				Standard: [],
				UseClassification: [],
				EnterpriseOperation: [],
				TaxClassification: [],
				InternationalRestrictions: [],
				PropertyRequirements: [],
				ProfessionalRequirements: [],
				CollateralTypes: [],
				Mortgagor: [],
				QuotaType: [],
				CollectionAccount: [],
				CrossBridge: [],
				WithdrawalRequirement: [],
				WhetherWhiteHouseholdsAdmitted: [],
				PreviousYearsLossesAllowedForAdmission: [],
				MicroloanBalanceAllowedForAdmission: [],
				PropertyRequirementActualController: []
			},
			mapOptions: [],
			dataObj: {},
		};
	},
	created() {
		this.getNetworkAreaList();
		this.getGradeList('ProductCategory', true);
		this.getGradeList('ProductRepaymentMethod');
		this.getGradeList('CheckCreditScan');
		this.getGradeList('CompanyOrPersonal');
		this.getGradeList('OnlineOrOffline');
		this.getGradeList('MortgageOrCredit');
		this.getGradeList('MortgageRate');
		this.getGradeList('MortgageRate');
		this.getGradeList('Standard');
		this.getGradeList('UseClassification');
		this.getGradeList('EnterpriseOperation');
		this.getGradeList('TaxClassification');
		this.getGradeList('InternationalRestrictions');
		this.getGradeList('PropertyRequirements');
		this.getGradeList('ProfessionalRequirements');
		this.getGradeList('CollateralTypes');
		this.getGradeList('Mortgagor');
		this.getGradeList('QuotaType');
		this.getGradeList('CollectionAccount');
		this.getGradeList('CrossBridge');
		this.getGradeList('WithdrawalRequirement');
		this.getGradeList('WhetherWhiteHouseholdsAdmitted');
		this.getGradeList('PreviousYearsLossesAllowedForAdmission');
		this.getGradeList('MicroloanBalanceAllowedForAdmission');
		this.getGradeList('PropertyRequirementActualController');
		this.getFinancialProductList();
	},
	methods: {
		async getNetworkAreaList() {
			const res = await networkAreaList();
			const myres = this.delchildren(res);
			localStorage.setItem('mapList', JSON.stringify(myres));
			this.mapOptions = myres;
		},
		delchildren(data) {
			data.forEach((item) => {
				if (!item.children.length) {
					delete item.children;
				} else {
					this.delchildren(item.children);
				}
			});
			return data;
		},
		async getGradeList(name, b = false) {
			const res = await itemByType({
				dictionaryType: name,
			});
			if (res.returncode === 0) {
				if (this[name]) {
					this[name] = res.data.map((item) => ({
						value: b ? item.itemValue : Number(item.itemValue),
						label: item.itemName,
					}));
				} else {
					this.allDictionaries[name] = res.data.map((item) => ({
						value: Number(item.itemValue),
						label: item.itemName,
					}));
				}
			}
		},
		async getFinancialProductList() {
			const res = await financialProductList(this.params);
			this.tableData = res.list;
			this.total = res.totalRows;
		},
		handleSizeChange(val) {
			this.params.pageSize = val;
			this.getFinancialProductList();
		},
		handleCurrentChange(val) {
			this.params.pageNo = val;
			this.getFinancialProductList();
		},
		handleEdit(index, row) {
			this.dataType = 'Edit';
			this.dialogVisible = true;
			this.dataObj = row;
		},
		examinehandle(index, row) {
			this.examineVisible = true;
			this.dataObj = row;
		},
		saveData() {
			this.getFinancialProductList();

			this.dialogVisible = false;
		},
		addtableData() {
			this.form.id = this.tableData[this.tableData.length - 1].id + 1;
			this.tableData.push(this.form);
			this.dialogVisible = false;
		},
		edittableData() {
			let index = this.tableData.findIndex(
				(item) => item.id == this.form.id
			);
			this.tableData.splice(index, 1, this.form);
			this.dialogVisible = false;
		},
		add() {
			this.dataType = 'Add';
			this.dialogVisible = true;
		},
	},
	components: {
		infoDrawer,
		examineDrawer,
	},
};
</script>
<style lang="scss" scoped>
.myproduct {
	width: 100%;
	height: calc(100vh - 150px);
}

.fy {
	text-align: end;
	padding: 10px 20px;
}
</style>
